import { ISocialMediaPost } from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { TemplateActions } from '../../../../admin/containers/templates/TemplateCategory';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IErrorMessageComponentProps } from '../../../../models/AppState';
import { getPlainTextPreviewForRawRichTextContent } from '../../../../models/UiUtils';
import { IRawRichTextContentState, ITemplateCard } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { IMoreMenuItem } from '../../MoreMenu';
import '../../richContent/RichContentDocumentEditor/placeholders.tinymce.css';
import { ImageMissingIcon } from '../../svgs/icons/ImageMissingIcon';
import { CampaignExpiryHint } from '../CampaignExpiryHint';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps, IErrorMessageComponentProps {
	card: ITemplateCard | ISocialMediaPost;
	className?: string;
	ctaText?: string;
	isFeatured?: boolean;
	onCtaClicked?(): void;
	onMenuItemClicked?(menuItem: IMoreMenuItem<TemplateActions>, e: React.MouseEvent<HTMLElement>): void;
}

const SocialCampaignCalendarTemplateCardBase: React.FC<IProps> = props => {
	const { card, ctaText, onCtaClicked } = props;
	const { text, truncated } = getPlainTextPreviewForRawRichTextContent(card?.content, {
		maxCharCount: 58,
	});
	const tCard = card as ITemplateCard;
	const expirationDate = tCard?.schedule?.expirationDate ? moment(tCard?.schedule?.expirationDate) : undefined;

	return (
		<div
			className={css(
				bs.p4,
				bs.gap2,
				bs.flex,
				bs.rounded,
				bs.relative,
				bs.bgWhite,
				bs.borderSolid,
				bs.border,
				bs.borderAntiFlashWhite,
				styleSheet.containerPostShadow,
				styleSheet.socialContainer
			)}
		>
			<figure className={css(bs.flex, bs.minH24, bs.w24, bs.flexShrink0, bs.p0, bs.m0)}>
				{card?.attachments?.length > 0 ? (
					card?.attachments?.[0].mimeType?.startsWith('video/') ? (
						<video className={css(bs.block, bs.wFull, bs.hFull, bs.objectCover)} src={card?.attachments[0]?.url} />
					) : (
						<img className={css(bs.block, bs.wFull, bs.hFull, bs.objectCover)} src={card?.attachments[0]?.url} />
					)
				) : (
					<ImageMissingIcon className={css(bs.wFull, bs.hFull, bs.objectCover)} />
				)}
			</figure>
			<div className={css(bs.flex, bs.flexCol, bs.overflowHidden, bs.flex1, bs.gap2)}>
				<div className={css(bs.flex, bs.itemsCenter, bs.gap2, bs.boxBorder, bs.wFull, bs.truncateText)}>
					<div className={css(bs.textSm, bs.textBrandPrimary, bs.truncateText)}>{card.name || 'Custom Post'}</div>
					{expirationDate ? <CampaignExpiryHint expirationDate={expirationDate} /> : null}
				</div>
				<div className={css(bs.h12)}>
					<p className={css(bs.bgTransparent, bs.textLabel, bs.textXs, bs.p0, bs.m0, bs.overflowHidden)}>
						<span>{text}</span>
						{truncated ? <span>{' ...'}</span> : null}
					</p>
				</div>
				{onCtaClicked && (
					<button className={css(baseStyleSheet.ctaButtonReverseSmall, bs.textXs)} onClick={onCtaClicked}>
						<span>{ctaText || 'View Social Media Post'}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export const SocialCampaignCalendarTemplateCard = inject(ImpersonationContextKey)(
	SocialCampaignCalendarTemplateCardBase
);

function BlogCampaignCalendarTemplateCardSummary({
	content,
	maxCharCount,
}: {
	content: IRawRichTextContentState;
	maxCharCount: number;
}) {
	const { text, truncated } = getPlainTextPreviewForRawRichTextContent(content, {
		maxCharCount,
	});

	return (
		<div className={css(styleSheet.textContainer)}>
			<p className={css(styleSheet.body)}>
				<span>{text}</span>
				{truncated ? <span>{' ...'}</span> : null}
			</p>
		</div>
	);
}

const BlogCampaignCalendarTemplateCardBase: React.FC<IProps> = props => {
	const { card, className, ctaText, onCtaClicked } = props;
	const tCard = card as ITemplateCard;
	const hasAssociatedTemplateOrCampaign = tCard.associatedTemplates?.length > 0;

	return (
		<div
			className={`${css(styleSheet.container, styleSheet.containerPost, styleSheet.containerPostShadow)}
			blog-campaign-template-card
			${className || ''}`}
		>
			<div className={css(styleSheet.summaryPadding)}>
				{card?.attachments?.length > 0 ? (
					<figure className={css(styleSheet.imageContainer)}>
						{card?.attachments?.[0].mimeType?.startsWith('video/') ? (
							<>
								<video className={css(styleSheet.imageFit)} src={card?.attachments[0]?.url} />
							</>
						) : (
							<img className={css(styleSheet.imageFit)} src={card?.attachments[0]?.url} />
						)}
					</figure>
				) : (
					<figure className={css(styleSheet.imageContainer)}>
						<ImageMissingIcon />
					</figure>
				)}
				<div className={css(styleSheet.columnCard)}>
					<div className={css(styleSheet.titleContainer)}>
						<div
							className={`campaign-template-card-name ${css(
								!hasAssociatedTemplateOrCampaign && styleSheet.name,
								styleSheet.postName,
								baseStyleSheet.truncateText
							)}`}
						>
							<div className={css(styleSheet.card)}>{card.name || 'Custom Post'}</div>
						</div>
					</div>
					<div className={css(styleSheet.textContainerSmall)}>
						<BlogCampaignCalendarTemplateCardSummary content={card?.content} maxCharCount={60} />
					</div>
					{onCtaClicked && (
						<button className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.button)} onClick={onCtaClicked}>
							<span>{ctaText || 'View Blog Post'}</span>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export const BlogCampaignCalendarTemplateCard = inject(ImpersonationContextKey)(BlogCampaignCalendarTemplateCardBase);
