import {
	IAccountTag,
	ICompany,
	IContact,
	IEntity,
	IHandleAutoCompleteResult,
	IUser,
	QuickAddEntityViewModel,
	ResourceAutoCompleteViewModelType,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import shallowEqual from 'shallowequal';
import * as Api from '../../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import {
	AutoCompleteResultFilter,
	IPhoneCallCategory,
	ResourceAutoCompleteViewModel,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { Modal } from '../../Modal';
import { QuickAddEntity } from '../../QuickAddEntity';
import '../../TextInput/styles.less';
import { ContactSearchListItem, IContactSearchListItemProps } from '../../contacts/ContactSearchListItem';
import { AddEntityButtons } from '../../entities/AddEntityButtons';
import { Tag } from '../../tags/Tag';
import { AutoCompleteDropdown } from '../AutoCompleteDropdown';
import { styleSheet } from './styles';

export enum AutoCompleteSearchFieldAccessoryViewMode {
	Always = 0,
	WhileEditing,
	Never,
}

export interface IAutoCompleteSearchFieldComponent {
	clearInput(): void;
	setSearchQuery(searchQuery?: string): void;
}

interface IProps<T = any> extends IUserSessionComponentProps, IImpersonationContextComponentProps {
	anchorClassName?: string;
	anchorStyles: StyleDeclarationValue[];
	autoCompleteFilter?: AutoCompleteResultFilter<T>;
	className?: string;
	clearSearchFieldAfterSelectingItem?: boolean;
	contentPositionY?: 'top' | 'bottom';
	/** Setting this to true makes the input act like a normal text input field */
	disableAutocomplete?: boolean;
	dropdownClassName?: string;
	dropdownContentClassName?: string;
	dropdownContentItemsStyles?: StyleDeclarationValue[];
	contentStyles?: StyleDeclarationValue[];
	dropdownItemClassName?: string;
	hideResultsFooter?: boolean;
	initialSearchQuery?: string;
	onCreateAutoCompleteViewModel?(
		type: ResourceAutoCompleteViewModelType,
		suggestedViewModel: ResourceAutoCompleteViewModel,
		autoCompleteFilter?: AutoCompleteResultFilter<any>
	): ResourceAutoCompleteViewModel;
	onInnerRef?(ref: IAutoCompleteSearchFieldComponent): void;
	inputId: string;
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

	/** Element to display to the left of the inner input field */
	leftAccessory?: React.ReactNode;
	sectionTitleElement?: React.ReactNode;

	onInputRef?: React.Ref<HTMLInputElement>;

	/**
	 * @param item Selected dropdown item (either from enter on the keyboard or an explicit mouse click)
	 * @param type ResourceAutoCompleteViewModelType
	 */
	onItemSelected?(item: IEntity | string | IUser, type: ResourceAutoCompleteViewModelType): void;

	onOpenChanged?(isOpen?: boolean): void;

	onRenderAnchorContent?(
		inputElement: React.ReactElement<any>,
		inputHasFocus: boolean,
		leftAccessory?: React.ReactNode,
		rightAccessory?: React.ReactNode
	): React.ReactNode;

	/**
	 * You can pass back a node to render at the bottom of the autocomplete list, or let the control render the default
	 * element.
	 *
	 * @param overflowItems List of items that the control did not render
	 */
	onRenderLimitedResults?(renderedItems: any[], overflowItems: any[], totalCount: number): React.ReactNode;

	onRenderResultsFooter?(
		searchQuery: string,
		renderedItems: any[],
		totalCount: number,
		overflowItems?: any[]
	): React.ReactNode;

	pageSize?: number;

	/** Placeholder text */
	placeholder?: string;
	preventImpersonation?: boolean;
	renderNoResultsItem?: boolean;

	resultsLimit?: number;

	retainFocusAfterSelectingItem?: boolean;

	/** Element to display to the left of the inner input field */
	rightAccessory?: React.ReactNode;

	/** By default this is AutoCompleteSearchFieldAccessoryViewMode.Always */
	rightAccessoryViewMode?: AutoCompleteSearchFieldAccessoryViewMode;

	/** Type of autocomplete service to provide */
	type?: ResourceAutoCompleteViewModelType;

	/** Make a search when autocomplete input gain focus */
	startSearchingWhenOpen?: boolean;
	customQueryParams?: Api.IDictionary<any>;
	openOnFocus?: boolean;
	isOpen?: boolean;
	emptyResults?: React.ReactNode | undefined;
}

interface IState<T = any> {
	autoCompleteFilter?: AutoCompleteResultFilter<T>;
	autoCompleteViewModel?: ResourceAutoCompleteViewModel<any>;
	disableAutocomplete?: boolean;
	hideResultsFooter?: boolean;
	inputHasFocus?: boolean;
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	searchQuery?: string;
	type?: ResourceAutoCompleteViewModelType;
}

class _AutoCompleteSearchField extends React.Component<IProps, IState> implements IAutoCompleteSearchFieldComponent {
	public static defaultProps: Partial<IProps> = {
		clearSearchFieldAfterSelectingItem: false,
		renderNoResultsItem: true,
		rightAccessoryViewMode: AutoCompleteSearchFieldAccessoryViewMode.Always,
		type: ResourceAutoCompleteViewModelType.Entity,
	};
	public state: IState = {};
	// @ts-ignore
	private dropdownElement: typeof AutoCompleteDropdown;
	private quickAddEntityVM: QuickAddEntityViewModel;
	constructor(props: IProps) {
		super(props);
		this.quickAddEntityVM = new QuickAddEntityViewModel(props.userSession);
	}

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		this.setState({
			...nextState,
			searchQuery: this.props.initialSearchQuery,
		});

		if (this.props.onInnerRef) {
			this.props.onInnerRef(this);
		}
	}

	public componentWillUnmount() {
		if (this.props.onInnerRef) {
			// @ts-ignore
			this.props.onInnerRef(null);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const {
			className = '',
			contentPositionY,
			customQueryParams,
			dropdownClassName,
			dropdownContentItemsStyles,
			initialSearchQuery,
			inputId,
			onInputRef,
			onOpenChanged,
			placeholder,
			resultsLimit,
			startSearchingWhenOpen,
			sectionTitleElement,
			openOnFocus = false,
			isOpen,
			contentStyles,
			anchorStyles,
		} = this.props;
		const { autoCompleteViewModel, inputProps, disableAutocomplete, searchQuery } = this.state;
		if (!autoCompleteViewModel) {
			return null;
		}

		// hook into onChange to capture search query changes
		const computedInputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> = {
			...(inputProps || {}),
			autoComplete: 'off',
			name: (inputProps || {}).name || inputId,
			onBlur: this.onInputFocusChanged(false, inputProps ? inputProps.onBlur : undefined),
			// @ts-ignore
			onChange: this.onTextInputChanged(inputProps ? inputProps.onChange : undefined),
			onFocus: this.onInputFocusChanged(true, inputProps ? inputProps.onFocus : undefined),
			// @ts-ignore
			placeholder: placeholder || (inputProps ? inputProps.placeholder : null),
			type: 'search',
		};

		return (
			<div className={className || undefined}>
				<AutoCompleteDropdown
					anchorStyles={anchorStyles}
					isOpen={isOpen}
					sectionTitleElement={sectionTitleElement}
					anchor={this.anchorProvider}
					autoCompleteViewModel={autoCompleteViewModel}
					className={dropdownClassName}
					contentPositionY={contentPositionY}
					contentStyles={contentStyles && contentStyles?.length > 0 ? contentStyles : undefined}
					customQueryParams={customQueryParams}
					disableAutocomplete={disableAutocomplete}
					dropdownContentClassName={`${css(styleSheet.dropdownContent)} ${this.props.dropdownContentClassName || ''}`}
					dropdownContentItemsStyles={dropdownContentItemsStyles}
					initialSearchQuery={initialSearchQuery}
					inputProps={computedInputProps}
					onInputRef={onInputRef}
					onItemSelected={this.onItemSelected}
					onOpenChanged={onOpenChanged}
					onRenderEmptyResults={this.onRenderEmptyResults}
					// @ts-ignore
					onRenderItem={this.onRenderItem}
					onRenderLimitedResults={this.onRenderLimitedResults}
					onRenderResultsFooter={this.onRenderResultsFooter}
					ref={this.onDropdownElementRef}
					resultsLimit={resultsLimit}
					searchQuery={searchQuery}
					startSearchingWhenOpen={startSearchingWhenOpen}
					openOnFocus={openOnFocus}
				/>
				<Modal
					isOpen={this.quickAddEntityVM.isOpen}
					onRequestClose={this.quickAddEntityVM.close}
					shouldCloseOnOverlayClick={true}
					useDefaultHeader={true}
				>
					<QuickAddEntity quickAddEntityVm={this.quickAddEntityVM} />
				</Modal>
			</div>
		);
	}

	public setSearchQuery = (searchQuery?: string) => {
		this.setState({
			searchQuery,
		});
	};

	public clearInput = () => {
		this.setState({
			// @ts-ignore
			searchQuery: null,
		});
	};

	private onInputFocusChanged =
		(hasFocus: boolean, onFocusChangedCallback?: React.FocusEventHandler<HTMLInputElement>) =>
		(e: React.FocusEvent<HTMLInputElement>) => {
			if (onFocusChangedCallback) {
				onFocusChangedCallback(e);
			}

			this.setState({
				inputHasFocus: hasFocus,
			});
		};

	private onRenderResultsFooter = (
		searchQuery: string,
		renderedItems: IEntity[] | string[] | IUser[],
		totalCount: number,
		overflowItems?: IEntity[] | string[] | IUser[]
	) => {
		const { onRenderResultsFooter } = this.props;
		if (onRenderResultsFooter) {
			const footer = onRenderResultsFooter(searchQuery, renderedItems, totalCount, overflowItems);
			if (footer) {
				return footer;
			}
		}

		const { type, hideResultsFooter } = this.state;
		if (
			!!hideResultsFooter ||
			(type !== ResourceAutoCompleteViewModelType.Contact &&
				type !== ResourceAutoCompleteViewModelType.Company &&
				type !== ResourceAutoCompleteViewModelType.Entity)
		) {
			return null;
		}
		return (
			<AddEntityButtons
				className={css(styleSheet.addEntityButtons)}
				fragment={searchQuery}
				onEntityCreated={this.onEntityCreated}
				quickAddEntityVM={this.quickAddEntityVM}
				type={type}
			/>
		);
	};

	private onEntityCreated = (entity: IEntity, entityType: 'contact' | 'company') => {
		const { type } = this.state;
		if (type === 'entity') {
			// need to get the entity into the format expected by onItemSelected
			const result: IHandleAutoCompleteResult = {};
			if (entityType === 'contact') {
				result.contact = entity as IContact;
			} else {
				result.company = entity as ICompany;
			}
			this.onItemSelected(result);
		} else {
			this.onItemSelected(entity);
		}
	};

	private onRenderLimitedResults = (renderedItems: any[], overflowItems: any[], totalCount: number) => {
		const { onRenderLimitedResults } = this.props;
		if (onRenderLimitedResults) {
			return onRenderLimitedResults(renderedItems, overflowItems, totalCount);
		}

		return null;
	};

	private onRenderEmptyResults = () => {
		if (this.props.renderNoResultsItem) {
			if (this.props.emptyResults) {
				return this.props.emptyResults;
			}
			return <div className={`autocomplete-no-results ${css(styleSheet.info)}`}>No results</div>;
		}

		return null;
	};

	private onDropdownElementRef = (ref: any) => {
		this.dropdownElement = ref;
	};

	private onTextInputChanged =
		(onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
			this.setState({
				searchQuery: e.target.value,
			});
			if (onChange) {
				onChange(e);
			}
		};

	private onItemSelected = (item: any) => {
		const { type } = this.state;
		const { retainFocusAfterSelectingItem } = this.props;
		let result: IEntity | string | IUser = item;
		let searchQuery = this.state.searchQuery;
		switch (type) {
			case 'entity': {
				const entity = (item as IHandleAutoCompleteResult).contact || (item as IHandleAutoCompleteResult).company;
				if (entity) {
					result = entity;
				}
				break;
			}
			case 'user': {
				const user: IUser = item;
				searchQuery = user.firstName + ' ' + user.lastName;
				break;
			}
			case 'contact': {
				const contact: IContact = item;
				searchQuery = contact.handle;
				break;
			}
			case 'company': {
				const company: ICompany = item;
				searchQuery = company.companyName;
				break;
			}
			case ResourceAutoCompleteViewModelType.LineOfBusiness:
			case 'tag': {
				const value: string = item;
				searchQuery = value;
				break;
			}
			case ResourceAutoCompleteViewModelType.PhoneCallCategory: {
				const tag: IPhoneCallCategory = item;
				searchQuery = tag.name;
				break;
			}
			case ResourceAutoCompleteViewModelType.AccountTag: {
				const tag: IAccountTag = item;
				searchQuery = tag.tag;
				break;
			}
			default: {
				break;
			}
		}

		this.setState({
			// @ts-ignore
			searchQuery: this.props.clearSearchFieldAfterSelectingItem ? null : searchQuery,
		});

		if (this.props.onItemSelected) {
			// @ts-ignore
			this.props.onItemSelected(result, type);
		}

		if (!!this.dropdownElement && !retainFocusAfterSelectingItem) {
			requestAnimationFrame(() => {
				if (this.dropdownElement) {
					(this.dropdownElement as any).blur();
				}
			});
		}
	};

	private anchorProvider = (inputElement: React.ReactElement<any>) => {
		const { rightAccessory, leftAccessory, anchorClassName, rightAccessoryViewMode, onRenderAnchorContent } =
			this.props;
		const { inputHasFocus } = this.state;
		const rightNode =
			rightAccessoryViewMode === AutoCompleteSearchFieldAccessoryViewMode.Never
				? null
				: (rightAccessoryViewMode === AutoCompleteSearchFieldAccessoryViewMode.Always ||
						(rightAccessoryViewMode === AutoCompleteSearchFieldAccessoryViewMode.WhileEditing && !!inputHasFocus)) &&
					rightAccessory;
		// @ts-ignore
		const externalContent = onRenderAnchorContent?.(inputElement, inputHasFocus, leftAccessory, rightNode);
		return (
			<div
				className={`autocomplete-search-field-input ${css(baseStyleSheet.textField, styleSheet.textInput)} ${
					anchorClassName || ''
				}`}
			>
				{externalContent || (
					<>
						{leftAccessory}
						{inputElement}
						{rightNode}
					</>
				)}
			</div>
		);
	};

	private onRenderItem = (
		item: IEntity | string | IHandleAutoCompleteResult,
		index: number,
		highlighted: boolean,
		onClick: (e?: React.MouseEvent<HTMLElement>) => void
	) => {
		const { type } = this.state;

		let itemContent: JSX.Element | string = '';

		switch (type) {
			case 'company':
			case 'contact':
			case 'entity':
			case 'manager':
			case 'user': {
				const props: IContactSearchListItemProps = {};
				if (type === 'contact' || type === 'company') {
					props.entity = item as IEntity;
				} else if (type === 'entity') {
					const handleResult = item as IHandleAutoCompleteResult;
					props.entity = handleResult.contact || handleResult.company;
				} else {
					props.user = item as IUser;
				}
				itemContent = <ContactSearchListItem {...props} showTitle={true} renderEmail={true} />;
				break;
			}

			case ResourceAutoCompleteViewModelType.Role: {
				itemContent = <div className={css(styleSheet.general, baseStyleSheet.truncateText)}>{item}</div>;
				break;
			}

			case ResourceAutoCompleteViewModelType.Team: {
				itemContent = <div className={css(styleSheet.general, baseStyleSheet.truncateText)}>{item}</div>;
				break;
			}

			case ResourceAutoCompleteViewModelType.VerticalV2:
			case ResourceAutoCompleteViewModelType.Vertical: {
				itemContent = <div className={css(styleSheet.general, baseStyleSheet.truncateText)}>{item}</div>;
				break;
			}

			case ResourceAutoCompleteViewModelType.PhoneCallCategory: {
				const tag = item as IPhoneCallCategory;
				itemContent = <div className={css(styleSheet.general, baseStyleSheet.truncateText)}>{tag.name}</div>;
				break;
			}

			case ResourceAutoCompleteViewModelType.LineOfBusiness:
			case ResourceAutoCompleteViewModelType.Tag: {
				const tag = item as string;
				itemContent = (
					<div className={css(styleSheet.tagWrap, baseStyleSheet.truncateText)}>
						<Tag className={css(styleSheet.tagStyles)} tagValue={{ tag }} />
					</div>
				);
				break;
			}
			case ResourceAutoCompleteViewModelType.AccountTag: {
				const tag = item as IAccountTag;
				itemContent = (
					<div className={css(styleSheet.tagWrap, baseStyleSheet.truncateText)}>
						<Tag className={css(styleSheet.tagStyles)} tagValue={{ tag: tag.tag }} />
					</div>
				);
				break;
			}
			default: {
				break;
			}
		}

		if (itemContent) {
			let key = Object.prototype.hasOwnProperty.call(item, 'id') ? (item as IEntity | IUser).id : '';
			if (!key) {
				const handleResult = item as IHandleAutoCompleteResult;
				const entity = handleResult.contact || handleResult.company;
				if (entity) {
					key = entity.id;
				}
			}
			if (!key) {
				key = `${index}`;
			}
			return (
				<div
					className={`${css(styleSheet.item, highlighted && styleSheet.highlighted)} item ${
						highlighted && 'highlighted'
					} ${this.props.dropdownItemClassName || ''}`}
					key={key}
					onMouseDown={onClick}
					tabIndex={0}
				>
					{itemContent}
				</div>
			);
		}
		return null;
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		if (nextProps === this.props || !shallowEqual(nextProps, this.props)) {
			const nextState: IState = {};
			if (nextProps.type !== this.state.type || nextProps.autoCompleteFilter !== this.state.autoCompleteFilter) {
				nextState.type = nextProps.type;
				nextState.autoCompleteFilter = nextProps.autoCompleteFilter;
				nextState.autoCompleteViewModel = this.createAutoCompleteViewModel(
					// @ts-ignore
					nextProps.type,
					nextProps.autoCompleteFilter
				);
				if (this.dropdownElement) {
					requestAnimationFrame(() => {
						if (this.dropdownElement) {
							(this.dropdownElement as any).reset();
						}
					});
				}
			}

			if (!shallowEqual(nextProps.inputProps, this.state.inputProps)) {
				nextState.inputProps = nextProps.inputProps;
			}

			if (nextProps.disableAutocomplete !== this.state.disableAutocomplete) {
				nextState.disableAutocomplete = nextProps.disableAutocomplete;
			}

			if (nextProps.hideResultsFooter !== this.state.hideResultsFooter) {
				nextState.hideResultsFooter = nextProps.hideResultsFooter;
			}

			return Object.keys(nextState).length > 0 ? nextState : null;
		}
		return null;
	};

	private createAutoCompleteViewModel = (
		type: ResourceAutoCompleteViewModelType,
		autoCompleteFilter?: AutoCompleteResultFilter<any>
	) => {
		const { onCreateAutoCompleteViewModel, pageSize, impersonationContext, preventImpersonation = false } = this.props;
		// @ts-ignore
		let vm: ResourceAutoCompleteViewModel = new ResourceAutoCompleteViewModel(this.props.userSession, {
			filter: autoCompleteFilter,
			inputDelay: 100,
			pageSize,
			type,
		});
		if (!preventImpersonation) {
			vm.impersonate(impersonationContext);
		}
		if (onCreateAutoCompleteViewModel) {
			vm = onCreateAutoCompleteViewModel(type, vm, autoCompleteFilter);
		}

		return vm;
	};
}

const AutoCompleteSearchFieldAsObserver = observer(_AutoCompleteSearchField);
export const AutoCompleteSearchField = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(AutoCompleteSearchFieldAsObserver);
