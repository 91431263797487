import { brandPrimaryHover } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	clearSearchLink: {
		color: brandPrimaryHover,
	},
	container: {},
	input: {
		background: '#fff',
	},
});
