import { borderColor, titles } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	inputContainer: {
		alignItems: 'center',
		background: '#f9f9f9',
		border: `1px solid #ccc`,
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		width: '100%',
		...CssSelectors.allChildren(
			{
				height: 21,
				margin: '0 2px 0 14px',
				width: 21,
			},
			'svg'
		),
		...CssSelectors.allChildren(
			{
				background: 'none',
				border: 'none',
				boxSizing: 'border-box',
				color: titles,
				fontSize: '14px',
				height: '100%',
				outline: 'none',
				padding: 10,
				width: '100%',
			},
			'input'
		),
	},
	resultsContainer: {
		background: '#fff',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.30)',
		boxSizing: 'border-box',
		marginTop: 2,
		padding: 10,
		width: 360,
	},
});
