import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, navigation } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addresslineCol: {
		flex: 1,
	},
	addresslineGroup: {
		display: 'flex',
		padding: '0.5rem 0',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	pluginAddresslineGroup: {
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				marginLeft: 0,
			},
			':not(:first-child)'
		),
		...CssSelectors.allChildren(
			{
				marginBottom: '0.5rem',
			},
			'div'
		),
	},
	customOutlineButton: {
		minWidth: 90,
		padding: '10px 20px',
	},
	label: {
		color: navigation,
		fontSize: 12,
		letterSpacing: 0,
	},
	modalContainer: {
		position: 'relative',
		width: 568,
	},
	pluginModalContainer: {
		width: 295,
	},
	modalFooter: {
		display: 'flex',
		marginTop: 30,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
			},
			'button'
		),
	},
	modalHeader: {
		marginBottom: 30,
	},
	modalHeaderName: {
		boxSizing: 'border-box',
		color: brandPrimaryText,
		fontSize: 24,
		margin: 0,
		width: '100%',
	},
	modalLoadingOverlay: {
		alignItems: 'center',
		background: 'rgba(255, 255, 255, .5)',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		position: 'absolute',
		width: '100%',
	},
});
