import { brandSecondary, darkGrayFontColor, titles } from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		color: darkGrayFontColor,
		fontSize: 14,
		position: 'relative',
	},
	firstNamePlaceholderPopoverContent: {
		background: '#fff',
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		width: 300,
	},
	firstNamePlaceholderPopoverContentBody: {
		padding: '8px 14px 12px 14px',
		...CssSelectors.allDescendants(
			{
				boxSizing: 'border-box',
				color: titles,
				padding: '0 10px',
			},
			'ul'
		),
	},
	firstNamePlaceholderPopoverContentHeader: {
		background: '#E8F3F9',
		display: 'flex',
		...baseStyleSheet.fontBold,
		padding: '8px 12px',
	},
	readOnlyContent: {
		border: 'none',
		boxSizing: 'border-box',
		color: titles,
		overflowWrap: 'break-word',
		padding: '10px 5px',
		position: 'relative',
		width: '100%',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
		zIndex: 0,
		...CssSelectors.allDescendants(
			{
				background: '#F3F3F3',
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
	},
});
