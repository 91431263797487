import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DraggableProvided, DraggableStateSnapshot, DroppableProvided } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { CopyTemplateLinkModal } from '../../../../admin/containers/templates/CopyTemplateLinkModal';
import { TemplateActions } from '../../../../admin/containers/templates/TemplateCategory';
import { Noop } from '../../../../extViewmodels/Utils';
import {
	CardSize,
	IImpersonationContextComponentProps,
	ImpersonationContextKey,
	InternalLevitateAccountIds,
} from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { HiddenDefaultMessageTemplateKey } from '../../../../models/MessageTemplates';
import { canViewContentCalendarAndCampaigns, getIndustryName } from '../../../../models/UiUtils';
import {
	EmailCampaignBrowserViewModel,
	ITemplate,
	ITemplateCard,
	Industry,
	KnownCategories,
	SocialMediaCampaignBrowserViewModel,
	TemplateType,
	TemplatesViewModel,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { IMoreMenuItem } from '../../MoreMenu';
import { ISelectOption, Select } from '../../Select';
import { DraggableObserver, DroppableObserver } from '../../helpers/dnd';
import { CampaignTemplateCard } from '../../templateCards/CampaignTemplateCard';
import { SocialCampaignCalendarTemplateCard } from './presentation';
import { styleSheet } from './styles';

interface IProps<T extends ITemplate = ITemplate>
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IImpersonationContextComponentProps,
		IFullscreenModalComponentProps {
	campaignBrowser?: EmailCampaignBrowserViewModel;
	cardSize?: CardSize;
	socialBrowser?: SocialMediaCampaignBrowserViewModel;
	cardContainerStyles?: StyleDeclarationValue[];
	cardCtaText?: string;
	className?: string;
	dragDropProps?: {
		droppableId: string;
		type: string;
		isDragDisabled?: boolean;
		/**
		 * Styles to apply to the temoplate card while dragging
		 */
		draggingStyles?: StyleDeclarationValue[];
	};
	isSearch?: boolean;
	onCampaignTemplateClicked?(id: string): Promise<any>;
	onFilterCategories?(categories: string[]): string[];
	onIndustrySelected?(industry: Industry): void;
	onTemplateTypeSelected?(templateType: CampaignType): void;
	onSelectedCategoryChanged?(category: string): void;
	onMenuItemClicked?(template: T, menuItem: IMoreMenuItem<'copy'>, e: React.MouseEvent<HTMLElement>): void;
	onRef?(ref?: ICampaignsByCategorySelectorComponent): void;
	onTemplatesLoaded?(): void;
	retainIndustryOnClearSearch?: boolean;
	selectedTemplate?: ITemplate;
	selectedTemplateType?: CampaignType;
	selectStyles?: StyleDeclarationValue[];
	showingSocialType?: boolean;
	showIndustrySelector?: boolean;
	styles?: StyleDeclarationValue[];
	templates?: TemplatesViewModel;
	useWholeCardAsCTA?: boolean;
}

interface IState {
	copyTemplate?: ITemplate;
	isCopyLinkModalOpen?: boolean;
	isSearch?: boolean;
	selectedCategory?: ISelectOption<string>;
	selectedIndustry?: ISelectOption<Industry>;
	selectedTemplateType?: ISelectOption<CampaignType>;
}

export interface ICampaignsByCategorySelectorComponent {
	loadTemplate?(indexOrCard?: number | ITemplateCard): Promise<ITemplate>;
	loadTemplates?(): Promise<void>;
	templateCards: ITemplateCard[];
}
const defaultCopyMenu = [
	{
		name: 'Copy Direct Link',
		representedObject: 'copy',
	},
];

class _CampaignsByCategorySelector
	extends React.Component<IProps, IState>
	implements ICampaignsByCategorySelectorComponent
{
	private mIsPersonal: boolean;
	private mCampaignBrowser: EmailCampaignBrowserViewModel;
	private mSocialBrowser: SocialMediaCampaignBrowserViewModel;
	private mTemplates: TemplatesViewModel;

	constructor(props: IProps) {
		super(props);
		this.mIsPersonal = !canViewContentCalendarAndCampaigns(props.userSession);
		this.mCampaignBrowser =
			props.campaignBrowser ||
			new EmailCampaignBrowserViewModel(props.userSession).impersonate(props.impersonationContext);
		this.mSocialBrowser =
			props.socialBrowser ||
			// FOLLOWUP: Resolve
			// @ts-ignore
			new SocialMediaCampaignBrowserViewModel(props.userSession).impersonate(props.impersonationContext);
		this.mTemplates =
			props.templates || new TemplatesViewModel(props.userSession).impersonate(props.impersonationContext);
		this.state = this.getDefaultState();
	}

	private cardsRef: React.Ref<HTMLDivElement> = React.createRef<HTMLDivElement>();

	public componentDidMount() {
		this.loadCategories();
		this.props.onRef?.(this);
	}

	public componentWillUnmount() {
		this.props.onRef?.(null);
	}

	public componentDidUpdate(prevProps: IProps) {
		const { isSearch, retainIndustryOnClearSearch } = this.props;
		const { selectedIndustry } = this.state;

		try {
			if (prevProps.isSearch !== isSearch && !isSearch) {
				this.setState(
					this.getDefaultState(
						retainIndustryOnClearSearch && selectedIndustry.dataContext,
						this.state.selectedTemplateType.dataContext
					)
				);
				this.loadCategories();
			}
		} catch (err) {
			// console.log('componentDidUpdate: ', err);
		}
	}

	private getDefaultState = (overrideIndustry?: Industry, overrideType?: CampaignType) => {
		const {
			isSearch,
			showIndustrySelector,
			userSession,
			impersonationContext,
			onIndustrySelected,
			onTemplateTypeSelected,
			onSelectedCategoryChanged,
		} = this.props;

		try {
			const userIndustry =
				overrideIndustry ??
				this.industryOptions.find(
					x =>
						x.dataContext ===
						(impersonationContext?.isValid
							? impersonationContext?.account?.additionalInfo?.industry
							: userSession?.account?.additionalInfo?.industry)
				)?.dataContext ??
				Industry.Insurance;

			const userCampaignType = overrideType ?? CampaignType.Email;

			let state: IState = {
				copyTemplate: null,
				isCopyLinkModalOpen: false,
				isSearch,
			};

			state = this.mIsPersonal
				? {
						...this.state,
						selectedCategory: {
							dataContext: KnownCategories.MyTemplates,
							id: 'selected-category',
						},
						selectedIndustry: {
							dataContext: Industry.Insurance,
							id: 'selected-industry',
						},
						selectedTemplateType: {
							dataContext: CampaignType.Email,
							id: 'selected-template-type',
							text: 'Email',
						},
					}
				: {
						...this.state,
						selectedCategory: {
							dataContext: KnownCategories.Featured,
							id: 'selected-category',
						},
						selectedIndustry: showIndustrySelector
							? {
									dataContext: userIndustry,
									id: 'selected-industry',
									text: getIndustryName(userIndustry),
								}
							: null,
						selectedTemplateType: {
							dataContext: userCampaignType,
							id: 'selected-template-type',
							text: userCampaignType === CampaignType.Email ? 'Email' : 'Social',
						},
					};

			onIndustrySelected?.(state.selectedIndustry.dataContext);
			onTemplateTypeSelected?.(state.selectedTemplateType.dataContext);
			onSelectedCategoryChanged?.(state.selectedCategory.dataContext);
			return state;
		} catch (err) {
			// console.log('getDefaultState: ', err);
		}
	};

	public render() {
		const {
			className,
			styles,
			cardSize,
			dragDropProps,
			selectStyles,
			cardContainerStyles,
			showingSocialType,
			isSearch,
			showIndustrySelector,
			selectedTemplate,
			useWholeCardAsCTA,
			cardCtaText,
		} = this.props;
		const isSocialEnabled = this.props.userSession?.account?.features?.socialMedia;
		return (
			<div
				className={`${css(styleSheet.sideBarContainer, ...(styles || []))} campaigns-by-category-selector ${
					className || ''
				}`}
			>
				{!this.mIsPersonal && !useWholeCardAsCTA && showingSocialType && isSocialEnabled && (
					<Select
						onOptionClick={this.onTemplateTypeClicked}
						options={this.templateTypeOptions}
						selectedOptionTitle={this.renderTemplateTypeTrigger()}
						styles={[styleSheet.select, ...(selectStyles || [])]}
						triggerStyles={[styleSheet.trigger]}
					/>
				)}
				{showIndustrySelector && !this.mIsPersonal && (
					<Select
						onOptionClick={this.onIndustryClicked}
						options={this.industryOptions}
						selectedOptionTitle={this.renderIndustryTrigger()}
						styles={[styleSheet.select, ...(selectStyles || [])]}
						triggerStyles={[styleSheet.trigger]}
					/>
				)}
				{!this.mIsPersonal && !isSearch && (
					<Select
						onOptionClick={this.onOptionClicked}
						options={this.categoryOptions}
						selectedOptionTitle={this.renderCategoryTrigger()}
						styles={[styleSheet.select, ...(selectStyles || [])]}
						triggerStyles={[styleSheet.trigger]}
					/>
				)}
				{dragDropProps ? (
					this.renderDraggable()
				) : (
					<div
						className={`card-container ${css(
							styleSheet.templateCards,
							showIndustrySelector ? styleSheet.templateCardsWithIndustrySelector : null,
							...(cardContainerStyles || []),
							this.mIsPersonal && styleSheet.personalCards
						)}`}
						ref={this.cardsRef}
					>
						{this.state.selectedTemplateType.dataContext === CampaignType.Email ||
						this.state.selectedTemplateType?.dataContext === null
							? this.templateCards?.map(card => {
									return (
										<CampaignTemplateCard
											card={card}
											className={css(styleSheet.templateCardContainer)}
											ctaText={cardCtaText}
											key={card.id}
											impersonationContext={this.props.impersonationContext}
											isAccountTemplate={
												!!this.state.selectedCategory?.dataContext &&
												this.state.selectedCategory.dataContext === KnownCategories.MyTemplates
											}
											onCtaClicked={this.onCampaignTemplateCardClicked(card)}
											onSocialClicked={this.onCampaignTemplateCardClicked(card, true)}
											cardSize={cardSize || CardSize.Medium}
											styles={[styleSheet.templateCard, selectedTemplate?.id === card.id && styleSheet.selectedCard]}
											useWholeCardAsCTA={useWholeCardAsCTA}
										/>
									);
								})
							: this.templateCards?.map(card => {
									return (
										<SocialCampaignCalendarTemplateCard
											card={card}
											ctaText='View Social Media Post'
											key={card.id}
											onCtaClicked={this.onCampaignTemplateCardClicked(card)}
										/>
									);
								})}
						{this.renderLoading()}
					</div>
				)}
			</div>
		);
	}

	private renderLabelTrigger({ label, text }: { label: string; text: string }) {
		return (
			<>
				<span className={css(styleSheet.triggerLabel)}>{label}</span>
				<span className={css(styleSheet.triggerText)}>{text}</span>
			</>
		);
	}

	private renderTemplateTypeTrigger() {
		const { selectedTemplateType } = this.state;
		return this.renderLabelTrigger({ label: 'Type:', text: selectedTemplateType?.text });
	}

	private renderIndustryTrigger() {
		const { selectedIndustry } = this.state;
		return this.renderLabelTrigger({ label: 'Industry:', text: selectedIndustry?.text });
	}

	private renderCategoryTrigger() {
		const { selectedCategory } = this.state;
		return this.renderLabelTrigger({ label: 'Category:', text: selectedCategory?.text });
	}

	@computed
	public get templateCards() {
		const { isSearch } = this.props;
		const { selectedCategory: selectedOption, selectedTemplateType } = this.state;
		const hiddenMessages = localStorage.getItem(HiddenDefaultMessageTemplateKey);
		const currentBrowser =
			selectedTemplateType.dataContext === CampaignType.Email ? this.mCampaignBrowser : this.mSocialBrowser;

		if (selectedOption?.dataContext === KnownCategories.All && currentBrowser.browseSection && !isSearch) {
			const templates = Object.keys(currentBrowser.browseSection).reduce<ITemplateCard[]>((result, curr) => {
				const categoryTemplates = currentBrowser.browseSection[curr];
				categoryTemplates.forEach(x => {
					if (!result.find(y => y.id === x.id)) {
						result.push(x);
					}
				});
				return result;
			}, []);
			return templates.filter(x => !hiddenMessages?.includes(x.id));
		}
		return currentBrowser?.categorySection?.toArray().filter(x => !hiddenMessages?.includes(x.id));
	}

	public loadTemplate(indexOrCard?: number | ITemplateCard): Promise<ITemplate> {
		const id: string = typeof indexOrCard === 'number' ? this.templateCards?.[indexOrCard]?.id : indexOrCard?.id;

		if (id) {
			return this.mTemplates.getById(id);
		}

		return null;
	}

	private renderDraggable() {
		const { dragDropProps, cardContainerStyles, cardCtaText } = this.props;
		const { isCopyLinkModalOpen, copyTemplate, selectedIndustry, selectedCategory } = this.state;
		const { draggingStyles, ...restDragDropProps } = dragDropProps || {};
		return (
			// FOLLOWUP: Resolve
			// @ts-ignore
			<DroppableObserver {...restDragDropProps}>
				{(droppableProvided: DroppableProvided) => {
					return (
						<div
							className={css(styleSheet.templateCards, ...(cardContainerStyles || []))}
							ref={droppableProvided.innerRef}
							{...droppableProvided.droppableProps}
						>
							{this.templateCards?.map((card, i) => {
								return (
									<DraggableObserver
										disableInteractiveElementBlocking={true}
										draggableId={card.id}
										index={i}
										isDragDisabled={dragDropProps.isDragDisabled}
										key={card.id}
										type={dragDropProps.type}
									>
										{(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
											return (
												<>
													<div
														ref={draggableProvided.innerRef}
														{...draggableProvided.draggableProps}
														{...draggableProvided.dragHandleProps}
													>
														{this.state.selectedTemplateType.dataContext === CampaignType.Email ? (
															<CampaignTemplateCard
																card={card}
																className={css(styleSheet.templateCardContainer)}
																copyMenu={defaultCopyMenu}
																ctaText={cardCtaText}
																key={card.id}
																isAccountTemplate={
																	!!this.state.selectedCategory?.dataContext &&
																	this.state.selectedCategory.dataContext === KnownCategories.MyTemplates
																}
																onCtaClicked={this.onCampaignTemplateCardClicked(card)}
																onSocialClicked={this.onCampaignTemplateCardClicked(card, true)}
																onMenuItemClicked={this.onMenuItemClicked(card)}
																cardSize={CardSize.Small}
																styles={[
																	styleSheet.templateCard,
																	draggableSnapshot.isDragging ? styleSheet.templateCardDragging : null,
																	draggableSnapshot.isDragging && draggingStyles ? draggingStyles : null,
																]}
															/>
														) : (
															<SocialCampaignCalendarTemplateCard
																card={card}
																key={card.id}
																isFeatured={selectedCategory?.text === 'Featured'}
																onCtaClicked={this.onCampaignTemplateCardClicked(card)}
															/>
														)}
													</div>
													{draggableSnapshot.isDragging && draggableProvided?.placeholder}
												</>
											);
										}}
									</DraggableObserver>
								);
							})}
							{this.renderLoading()}
							<CopyTemplateLinkModal
								isOpen={isCopyLinkModalOpen}
								onRequestClose={this.onRequestCopyLinkClose}
								industry={selectedIndustry.dataContext}
								template={copyTemplate}
							/>
						</div>
					);
				}}
			</DroppableObserver>
		);
	}

	private renderLoading() {
		return this.mCampaignBrowser?.isBusy ? (
			<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
		) : null;
	}

	private onRequestCopyLinkClose = () => {
		this.onCopyDirectLink(false)(null);
	};

	@computed
	private get categoryOptions(): ISelectOption<string>[] {
		const { onFilterCategories, impersonationContext } = this.props;
		const { selectedTemplateType } = this.state;
		const id = uuid();
		let categories =
			selectedTemplateType.dataContext === CampaignType.Social
				? this.mSocialBrowser.categories
				: this.mCampaignBrowser.categories;
		if (onFilterCategories) {
			categories = onFilterCategories(categories);
		} else if (
			impersonationContext?.isValid &&
			!impersonationContext?.account?.preferences?.csmViewMyCampaignsEnabled
		) {
			categories = categories?.filter(x => x !== KnownCategories.MyTemplates);
		}
		categories = categories?.filter(x => x !== KnownCategories.SocialMediaDrafts);

		return (
			categories?.map((x, i) => ({
				dataContext: x,
				id: `category-option-${id}-${i}`,
				text: x,
			})) || []
		);
	}

	@computed
	private get industryOptions(): ISelectOption<Industry>[] {
		const industryOptions = [
			Industry.Insurance,
			Industry.RealEstate,
			Industry.Financial,
			Industry.Mortgage,
			Industry.Legal,
			Industry.Accounting,
			Industry.NonProfit,
			Industry.HomeMaintenance,
			Industry.Others,
		];

		const industries = InternalLevitateAccountIds.has(this.props.userSession.account.id)
			? [...industryOptions, Industry.Levitate]
			: industryOptions;
		return industries.map(x => {
			const name = getIndustryName(x);
			return {
				dataContext: x,
				id: `industry-option-${name}`,
				text: name,
			};
		});
	}

	@computed
	private get templateTypeOptions(): ISelectOption<CampaignType>[] {
		const templateTypeOptions = [CampaignType.Email, CampaignType.Social];
		return templateTypeOptions.map(x => {
			return {
				dataContext: x,
				id: `template-type-option-${x}`,
				text: x.valueOf(),
			};
		});
	}

	private onCampaignTemplateCardClicked = (card: ITemplateCard, socialIndicator?: boolean) => () => {
		const templateId = socialIndicator
			? card.associatedTemplates?.find(x => x.templateType === TemplateType.SocialMediaPost)?.id
			: card.id;
		if (templateId) {
			this.props.onCampaignTemplateClicked?.(templateId);
		}
	};

	private loadCategories = async () => {
		const { errorMessages, logApiError } = this.props;
		const { selectedIndustry, selectedTemplateType } = this.state;
		try {
			if (!this.mIsPersonal) {
				await (selectedTemplateType.dataContext === CampaignType.Social
					? this.mSocialBrowser.loadCategories(selectedIndustry?.dataContext)
					: this.mCampaignBrowser.loadCategories(selectedIndustry?.dataContext));
				this.setState(
					{
						selectedCategory:
							this.categoryOptions.find(x => x.dataContext === KnownCategories.Featured) ??
							this.categoryOptions.find(x => x.dataContext === KnownCategories.All),
					},
					this.loadTemplates
				);
			} else {
				this.loadTemplates();
			}
		} catch (err) {
			logApiError('CategoriesLoad-Error', err);
			errorMessages.pushApiError(err);
		}
	};

	public loadTemplates = async () => {
		const { logApiError, errorMessages, onTemplatesLoaded } = this.props;
		const { selectedCategory: selectedOption, selectedIndustry, selectedTemplateType } = this.state;
		const industry = selectedIndustry?.dataContext;
		try {
			if (selectedTemplateType.dataContext === CampaignType.Email) {
				if (selectedOption?.dataContext === KnownCategories.MyTemplates) {
					await this.mCampaignBrowser.loadMe(industry);
				} else if (selectedOption?.dataContext === KnownCategories.All) {
					await this.mCampaignBrowser.loadAll(industry);
				} else {
					await this.mCampaignBrowser.loadTemplates(industry, selectedOption?.dataContext);
				}
			} else {
				if (selectedOption?.dataContext === KnownCategories.SocialMediaDrafts) {
					await this.mSocialBrowser.loadSocialDrafts();
				} else if (selectedOption?.dataContext === KnownCategories.All) {
					await this.mSocialBrowser.loadAll(industry);
				} else {
					await this.mSocialBrowser.loadTemplates(industry, selectedOption?.dataContext);
				}
			}
			onTemplatesLoaded?.();
			// FOLLOWUP: Resolve
			// @ts-ignore
			const cards = this.cardsRef.current;
			if (cards) {
				cards.scrollTop = 0;
			}
		} catch (err) {
			logApiError('TemplatesLoad-Error', err);
			errorMessages.pushApiError(err);
		}
	};

	private onOptionClicked = (option: ISelectOption<string>, wasSelected: boolean) => {
		if (wasSelected) {
			this.props.onSelectedCategoryChanged?.(option.dataContext);
			this.setState(
				{
					selectedCategory: option,
				},
				this.loadTemplates
			);
		}
	};

	private onIndustryClicked = (option: ISelectOption<Industry>, wasSelected: boolean) => {
		const { onIndustrySelected, isSearch } = this.props;
		if (wasSelected) {
			onIndustrySelected?.(option.dataContext);
			this.setState(
				{
					selectedCategory:
						this.categoryOptions.find(x => x.dataContext === KnownCategories.Featured) ??
						this.categoryOptions.find(x => x.dataContext === KnownCategories.All),
					selectedIndustry: option,
				},
				isSearch ? Noop : this.loadCategories
			);
		}
	};

	private onTemplateTypeClicked = (option: ISelectOption<CampaignType>) => {
		const { onTemplateTypeSelected, isSearch } = this.props;
		onTemplateTypeSelected?.(option.dataContext);
		this.setState(
			{
				selectedCategory:
					this.categoryOptions.find(x => x.dataContext === KnownCategories.Featured) ??
					this.categoryOptions.find(x => x.dataContext === KnownCategories.All),
				selectedTemplateType: option,
			},
			isSearch ? Noop : this.loadCategories
		);
	};

	private onCopyDirectLink = (open: boolean) => (template: ITemplate) => {
		this.setState({ copyTemplate: template, isCopyLinkModalOpen: open });
	};
	private onMenuItemClicked = (template: ITemplate) => async (menuItem: IMoreMenuItem<TemplateActions>) => {
		switch (menuItem.representedObject) {
			case 'copy':
				this.onCopyDirectLink?.(true)(template);
				break;
			default:
				break;
		}
	};
}

const CampaignsByCategorySelectorAsObserver = observer(_CampaignsByCategorySelector);
const CampaignsByCategorySelectorWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ImpersonationContextKey,
	FullScreenModalViewModelKey
)(CampaignsByCategorySelectorAsObserver);
export const CampaignsByCategorySelector = withEventLogging(
	CampaignsByCategorySelectorWithContext,
	'CampaignsByCategorySelector'
);
